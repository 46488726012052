import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import PTBR from "./translations/pt-br.json";
import ENUS from "./translations/en-us.json";

i18n.use(initReactI18next).init({
  fallbackLng: "ptBr",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    ptBr: PTBR,
    en: ENUS,
  },
});
export default i18n;
