import { HeaderContainer } from "./style";

import logo from "../../assets/footer/logoWhite.svg";
import { Link, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import br from "../../assets/header/br.png";
import { useEffect, useState } from "react";
import us from "../../assets/header/us.png";
import { useTranslation } from "react-i18next";
import * as Styled from "./style.js";

export default function Footer() {
  const { t, i18n } = useTranslation();
  const [menuIsVisible, setMenuIsVisible] = useState(false);
  const path = useLocation().pathname;
  useEffect(() => {
    function scrollUp() {
      window.scroll(0, 0);
    }
    scrollUp();
  }, [path]);

  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    const elementOnscroll = document.addEventListener("scroll", function () {
      if (window.scrollY > 2300) {
        setAnimation(true);
      } else {
        setAnimation(false);
      }
    });

    document.addEventListener("DOMContentLoaded", function () {
      elementOnscroll();
    });
  }, []);
  useEffect(() => {
    const elementOnscroll = document.addEventListener("scroll", function () {
      if (window.scrollY > 100) {
        setAnimation(true);
      }
    });

    document.addEventListener("DOMContentLoaded", function () {
      elementOnscroll();
    });
  }, []);

  const Persons = [
    {
      name: t("FooterRoziName"),
      role: t("FooterRoziRole"),
      number: t("FooterRoziNumber"),
      email: t("FooterRoziEmail"),
      mailto: "mailto:rozi@doxapesquisas.com.br",
      link: "https://api.whatsapp.com/send?phone=5511999397934",
      id: 1,
    },
    {
      name: t("FooterEuniceName"),
      role: t("FooterEuniceRole"),
      number: t("FooterEuniceNumber"),
      email: t("FooterEuniceEmail"),
      mailto: "mailto:eunice@doxapesquisas.com.br",
      link: "https://api.whatsapp.com/send?phone=5511940241742",
      id: 2,
    },
  ];
  return (
    <Styled.Container>
      <div className="container">
        <div className="header">
          <nav className="header">
            <div className="content">
              <ul className="menuSide">
                <ScrollLink to="SOBRE" className="pc">
                  <li
                    className={`${
                      path === "/a" ? " color Options" : "Options"
                    }`}
                    onClick={() => setMenuIsVisible(false)}
                  >
                    {t("HeaderAbout")}
                  </li>
                </ScrollLink>{" "}
                <ScrollLink to="servicos" className="pc">
                  <li
                    className={`${
                      path === "/a" ? " color Options" : "Options"
                    }`}
                    onClick={() => setMenuIsVisible(false)}
                  >
                    {t("HeaderServices")}
                  </li>
                </ScrollLink>
                <ScrollLink to="rozi" className="pc">
                  <li
                    className={`${
                      path === "/a" ? " color Options" : "Options"
                    }`}
                    onClick={() => setMenuIsVisible(false)}
                  >
                    {t("HeaderCEO")}
                  </li>
                </ScrollLink>
              </ul>
              <Link to="/">
                <div className="logoSide">
                  <img src={logo} alt="logotipo" />
                </div>
              </Link>
              <ul className="menuSide">
                <ScrollLink to="contato">
                  <li
                    className={`${
                      path === "/a" ? " color Options" : "Options"
                    }`}
                    onClick={() => setMenuIsVisible(false)}
                  >
                    {t("HeaderContact")}
                  </li>
                </ScrollLink>
                <ScrollLink to="cliente">
                  <li
                    className={`${
                      path === "/a" ? " color Options " : "Options"
                    }`}
                    onClick={() => setMenuIsVisible(false)}
                  >
                    {t("HeaderClients")}
                  </li>
                </ScrollLink>
                <ScrollLink to="SOBRE" className="mobileList">
                  <li
                    className={`${
                      path === "/a" ? " color Options" : "Options"
                    }`}
                    onClick={() => setMenuIsVisible(false)}
                  >
                    {t("HeaderAbout")}
                  </li>
                </ScrollLink>{" "}
                <ScrollLink to="servicos" className="mobileList">
                  <li
                    className={`${
                      path === "/a" ? " color Options" : "Options"
                    }`}
                    onClick={() => setMenuIsVisible(false)}
                  >
                    {t("HeaderServices")}
                  </li>
                </ScrollLink>
                <ScrollLink to="rozi" className="mobileList">
                  <li
                    className={`${
                      path === "/a" ? " color Options" : "Options"
                    }`}
                    onClick={() => setMenuIsVisible(false)}
                  >
                    {t("HeaderCEO")}
                  </li>
                </ScrollLink>
                <div className="imgCotianer">
                  <img
                    src={br}
                    alt="Brazil"
                    onClick={() => {
                      i18n.changeLanguage("ptBr");
                    }}
                  />
                  <img
                    src={us}
                    alt="Us"
                    onClick={() => {
                      i18n.changeLanguage("en");
                    }}
                  />
                </div>
              </ul>
            </div>
          </nav>
        </div>
        <hr />
        <div className="ContactContainer col">
          <h4>{t("FooterTitleContact")}</h4>
          <div className="contact row">
            {Persons.map((item) => (
              <div className="Person col" id={item.key}>
                <p>{item.name}</p>
                <p>{item.role}</p>
                <a href={item.link} target="blanked">
                  <p>{item.number}</p>
                </a>
                <a href={item.mailto} target="blanked">
                  <p>{item.email}</p>
                </a>
              </div>
            ))}
          </div>
        </div>
        <hr />
        <div className="MapContainer col">
          <div className="contact row">
            <div className="titleMap Container col">
              <h4>{t("FooterGoogleMapsTitle")}</h4>
              <p>{t("FooterGoogleMapsText")}</p>
            </div>
            <iframe
              className="iframe"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14624.786216661683!2d-46.60523690415692!3d-23.59728278735313!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5b92fc955ed7%3A0xe8ef99e76bc61ff8!2sR.%20Gon%C3%A7alves%20Ledo%2C%2061%20-%20Ipiranga%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2004216-030!5e0!3m2!1spt-BR!2sbr!4v1695925581201!5m2!1spt-BR!2sbr"
              width="600"
              height="450"
              allowFullScreen={true}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
        <div className="privacypart row">
          <Link to="/politica-privacidade">
            <p>{t("FooterPolitica")}</p>
          </Link>
          <p>{t("FooterCopyrigth")}</p>
          <Link to="/politica-privacidade">
            <p>{t("FooterTermos")}</p>
          </Link>
        </div>
      </div>
    </Styled.Container>
  );
}
