import styled, { css } from "styled-components/";
const Media = {
  PhoneLarge: "@media(max-width:600px)",
  Laptop: "@media(max-width:1150px)",
  Tablet: "@media(max-width:1000px)",
  PhoneSmall: "@media(max-width:320px)",
};

export const Container = styled.footer`
  background: var(--Green-300, #4d9476);

  .container {
    min-height: 30rem;
    width: 85%;
    max-width: 80rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4rem;
    flex-direction: column;
    margin: auto;
    margin-top: 1rem;
    ${Media.Tablet} {
      min-height: 26rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 2rem;
    }
    ${Media.PhoneLarge} {
      width: 90%;
      max-width: 90rem;
    }

    .col {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .row {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
    }

    .privacypart {
      width: 90%;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: space-between !important;
      ${Media.Tablet} {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 1rem;
      }
      p {
        color: #fff;
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22.8px;
        cursor: pointer;
      }
    }
    .header {
      z-index: 99;
      width: 100%;
      top: 1rem;
      ${Media.Tablet} {
        display: none;
      }
      ul {
        list-style-type: none; /* Remove os marcadores padrão */
        padding-left: 0; /* Remove o recuo à esquerda padrão */
      }

      ul,
      li {
        text-decoration: none;
      }
      .logoSide {
        cursor: pointer;
      }
      .content {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        height: 6rem;
        width: 95%;
        max-width: 90rem;
        margin: auto;
        padding: 0 1rem;
        border-radius: 20px;
        ${Media.Nav} {
          width: 85%;
          max-width: 80rem;
          justify-content: space-between;
        }
        .menu {
          display: none;
          ${Media.Nav} {
            display: block;
            width: 3em;
            height: 3em;
            color: var(--Green-900);
            opacity: 1;
            transition: all 0.5s linear 0.1s;
            ${({ isVisible }) =>
              isVisible &&
              css`
                opacity: 0;
                transition: all 0.5s linear 0.1s;
              `}
          }
        }
        .closeMenu {
          position: absolute;
          opacity: 0;
          z-index: -1;
          right: 7%;
          transition: all 0.1s linear;

          ${Media.Nav} {
            ${({ isVisible }) =>
              isVisible &&
              css`
                z-index: 0;
                opacity: 1;
              `}
          }
        }
        .menuSide {
          ${Media.Nav} {
            width: 100%;
            height: 100vh;
            margin-left: 100%;
            padding: 0 45px 136px 0px;
            position: absolute;
            top: -120px;
            bottom: 34px;
            z-index: -2;
            align-items: flex-end;
            flex-direction: column;
            backdrop-filter: blur(25px);
            background: rgb(0 0 0 / 40%);

            ${({ isVisible }) =>
              isVisible &&
              css`
                transition: margin-left 0.8s cubic-bezier(0.65, 0.05, 0.36, 1);
                opacity: 1;
                margin-left: 1px;
                left: -42px;
              `}
          }
          gap: 2rem;
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .Options {
            color: var(--white);
            font-family: Lato;
            font-size: 22px;
            font-weight: 400;
            position: relative;

            ${({ isanimation }) =>
              isanimation &&
              css`
                &:after {
                  content: "";
                  position: absolute;
                  width: 100%;
                  height: 0.5rem;
                  top: 1.2rem;
                  left: 0;
                  z-index: -1;
                  ${Media.Nav} {
                    display: none;
                  }
                }
              `}

            ${Media.Nav} {
              font-size: 2rem;
              font-weight: bold;
              color: var(--white);
            }
          }
          .color {
            &:after {
              content: "";
              position: absolute;
              width: 100%;
              height: 0.5rem;
              top: 1.2rem;
              background-color: var(--greenThin);
              left: 0;
              z-index: -1;
              ${Media.Nav} {
                display: none;
              }
            }

            ${({ isanimation }) => isanimation && css``}
          }
        }
      }

      .mobileList {
        display: none;
        ${Media.Nav} {
          display: block;
        }
      }

      .pc {
        display: block;
        ${Media.Nav} {
          display: none;
        }
      }
      .imgCotianer {
        display: flex;
        align-items: center;
        gap: 1rem;
        img {
          width: 2.4rem;
          cursor: pointer;
        }
      }
    }

    hr {
      width: 100%;
      height: 1px;
      background: var(--white, #fafafa);
    }
    .ContactContainer {
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      gap: 1rem;

      h4 {
        color: var(--Yellow);
        font-family: Lato;
        font-size: 25.9px;
        font-weight: 400;
      }
    }
    .contact,
    .MapContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      ${Media.Tablet} {
        flex-direction: column;
        align-items: flex-start;
        gap: 3rem;
      }
      .Person {
        align-items: flex-start;
        gap: 0.6rem;
        p:first-child {
          color: var(--white);
          font-family: Lato;
          font-size: 19.814px;
          font-weight: 700;
        }

        p:nth-child(2n) {
          color: var(--white, #fafafa);
          font-family: Lato;
          font-size: 16.81px;
          font-style: italic;
          font-weight: 900;
        }
        p:nth-child(3n) {
          color: var(--white, #fafafa);
          font-family: Lato;
          font-size: 19.814px;
          font-weight: 300;
        }
        p:last-child {
          color: var(--white, #fafafa);
          font-family: Lato;
          font-size: 19.814px;
          font-weight: 300;
        }
      }
    }
    .MapContainer {
      iframe {
        ${Media.Tablet} {
          width: 100%;
        }
      }
    }
    .titleMap {
      align-items: flex-start;
      gap: 1rem;
      h4 {
        color: var(--Yellow);
        font-family: Lato;
        font-size: 25.9px;
        font-weight: 400;
      }
      p {
        color: var(--white);
        font-family: Lato;
        font-size: 19.814px;
        font-weight: 700;
      }
    }
  }
`;
