import { HeaderContainer } from "./style";
import logo from "../../assets/header/logo-extend-green.svg";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import br from "../../assets/header/br.png";
import us from "../../assets/header/us.png";
import { useTranslation } from "react-i18next";

export default function Header() {
  const { t, i18n } = useTranslation();
  const [menuIsVisible, setMenuIsVisible] = useState(false);
  const path = useLocation().pathname;

  useEffect(() => {
    function scrollUp() {
      window.scroll(0, 0);
    }
    scrollUp();
  }, [path]);

  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 2300) {
        setAnimation(true);
      } else {
        setAnimation(false);
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = (sectionId) => {
    if (path === "/") {
      // Se estiver na página inicial, role suavemente para a seção
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      // Se estiver em outra página, redirecione para a página inicial
      window.location.href = "/";
    }
    setMenuIsVisible(false);
  };

  // Verificar se o endereço atual é a página inicial
  const isHomePage = path === "/";

  return (
    <>
      <HeaderContainer isVisible={menuIsVisible} isanimation={animation}>
        <nav className="header">
          <div className="content">
            <ul className="menuSide">
              <a href="#" className="pc" onClick={() => handleClick("SOBRE")}>
                <li className={`${isHomePage ? "Options" : "Options"}`}>
                  {t("HeaderAbout")}
                </li>
              </a>
              <a
                href="#"
                className="pc"
                onClick={() => handleClick("servicos")}
              >
                <li className={`${isHomePage ? "Options" : "Options"}`}>
                  {t("HeaderServices")}
                </li>
              </a>
              <a href="#" className="pc" onClick={() => handleClick("rozi")}>
                <li className={`${isHomePage ? "Options" : "Options"}`}>
                  {t("HeaderCEO")}
                </li>
              </a>
            </ul>
            <a href="/">
              <div className="logoSide">
                <img src={logo} alt="logotipo" />
              </div>
            </a>

            <AiOutlineMenu
              onClick={() => setMenuIsVisible(true)}
              className="menu"
            />
            <AiOutlineClose
              onClick={() => setMenuIsVisible(false)}
              className="menu closeMenu"
            />
            <ul className="menuSide">
              <a href="#" className="pc" onClick={() => handleClick("contato")}>
                <li className={`${isHomePage ? "Options" : "Options"}`}>
                  {t("HeaderContact")}
                </li>
              </a>
              <a href="#" className="pc" onClick={() => handleClick("cliente")}>
                <li className={`${isHomePage ? "Options" : "Options"}`}>
                  {t("HeaderClients")}
                </li>
              </a>
              <a
                href="#"
                className="mobileList"
                onClick={() => handleClick("SOBRE")}
              >
                <li className={`${isHomePage ? "Options" : "Options"}`}>
                  {t("HeaderAbout")}
                </li>
              </a>
              <a
                href="#"
                className="mobileList"
                onClick={() => handleClick("servicos")}
              >
                <li className={`${isHomePage ? "Options" : "Options"}`}>
                  {t("HeaderServices")}
                </li>
              </a>
              <a
                href="#"
                className="mobileList"
                onClick={() => handleClick("rozi")}
              >
                <li className={`${isHomePage ? "Options" : "Options"}`}>
                  {t("HeaderCEO")}
                </li>
              </a>
              <div className="imgCotianer">
                <img
                  src={br}
                  alt="Brazil"
                  onClick={() => {
                    i18n.changeLanguage("ptBr");
                  }}
                />
                <img
                  src={us}
                  alt="Us"
                  onClick={() => {
                    i18n.changeLanguage("en");
                  }}
                />
              </div>
            </ul>
          </div>
        </nav>
      </HeaderContainer>
    </>
  );
}
