import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Loading from "./components/loading/index";
const Home = lazy(() => import("./pages/homepage/index"));
const Policy = lazy(() => import("./pages/policy/index"));
export function Router() {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/politica-privacidade" element={<Policy />} />
      </Routes>
    </Suspense>
  );
}
