import React from "react";
import "./i18n";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./reset.css";
import Button from "./components/buttonWpp fixed/index";
import Header from "./components/header/index";
import Footer from "./components/footer/index";
import { Router } from "./router";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Button />
      <Header />
      <Router />
      <Footer />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
