import * as Styled from "./style.js";
import { AiOutlineWhatsApp } from "react-icons/ai";
export default function WppButton() {
  return (
    <Styled.Container aria-label="Botão para o numero de contato">
      <a
        href="https://api.whatsapp.com/send?phone=5511940241742"
        target="blanked"
      >
        <AiOutlineWhatsApp className="BUttonwhatsApp" aria-label="" />
      </a>
    </Styled.Container>
  );
}
